<div *ngIf="!ref.created && loading" class="loading-container"><app-loading></app-loading></div>
<span *ngIf="ref.created" class="gutter" [class.deleted]="deleted">
  <a *ngIf="ref.origin && !authors.length"
     class="origin tag"
     [routerLink]="['/tag', ref.origin]">
    {{ ref.origin }}</a>
            <ng-container *ngIf="authorExts$ | async as authorExts; else authorNoExts">
              <ng-container *ngFor="let user of authorExts">
                <a class="user tag"
                   [title]="formatAuthor(user.tag + user.origin)"
                   [routerLink]="['/tag', user.tag + user.origin]"
                   [appTitle]="user">{{ user.name || formatAuthor(user.tag) }}</a>
              </ng-container>
            </ng-container>
            <ng-template #authorNoExts>
              <ng-container *ngFor="let t of authors">
                <a class="user tag"
                   [title]="formatAuthor(t)"
                   [routerLink]="['/tag', t]">{{ formatAuthor(t) }}</a>
              </ng-container>
            </ng-template>
  <span class="timestamp" [title]="ref.modified?.toISOString()">{{ ref.modified?.fromNow() }}</span>
  &nbsp;|&nbsp;
</span>
<span class="message"
      [class.bubble]="focused"
      [class.focused]="focused"
      [class.deleted]="deleted"
      [class.sending]="!ref.created"
      (pointerenter)="allowActions = true"
      (pointerleave)="allowActions = false">
  <div [class.has-title]="clickableLink || title && !focused">
    @if (clickableLink && (title && !focused || !media)) {
      <app-nav [url]="ref.url" [text]="title" [external]="true"></app-nav>
    } @else {
      <span>{{ title }}</span>
    }
    <app-md *ngIf="focused && bareRef?.comment"
            [origin]="ref.origin!"
            [text]="bareRef!.comment!"
            [plugins]="bareRef!.tags!"></app-md>
    <app-viewer [ref]="noComment"></app-viewer>
  </div>
  <div class="actions"
       *ngIf="ref.created"
       [style.pointer-events]="allowActions ? 'auto' : 'none'">
    <a *ngIf="comments"
       [routerLink]="['/ref', ref.url, 'comments']" [queryParams]="{ origin: nonLocalOrigin }" i18n>{comments, plural, =1 {1&thinsp;comment} other {{{ comments }}&thinsp;comments}}</a>
    <a *ngIf="!comments"
       [routerLink]="['/ref', ref.url]" [queryParams]="{ origin: nonLocalOrigin }" i18n>permalink</a>
        <app-confirm-action #action *ngIf="store.account.mod || writeAccess" [action]="delete$" i18n>delete</app-confirm-action>
        <app-inline-tag #action *ngIf="taggingAccess" [action]="tag$" i18n>tag</app-inline-tag>
    <a *ngIf="!ref.origin && store.account.mod && !approved && !locked"
       class="fake-link"
       (click)="approve()" i18n>approve</a>
    <span class="approved icon" *ngIf="store.account.mod && approved" i18n-title title="Moderated" i18n>✔️</span>
  </div>
</span>
@for (e of serverError; track e) {
  <span><!-- Unexpected Error --></span>
  <div class="error">{{ e }}</div>
}
