<ng-container *mobxAutorun>
  <app-tabs>
    <a class="logo" routerLink="/"></a>
    <h5 i18n>Settings</h5>
    @if (admin.getTemplate('user') && store.account.localTag) {
      <a routerLink="./me" routerLinkActive="current-tab" i18n-title title="My settings" i18n>me</a>
    }
    @if (store.account.admin) {
      <a routerLink="./setup" routerLinkActive="current-tab" i18n-title title="Setup" i18n>setup</a>
    }
    @for (plugin of store.view.settingsTabs; track plugin.tag) {
      <a [routerLink]="['./ref', plugin.tag]"
         [replaceUrl]="true"
         routerLinkActive="current-tab"
         queryParamsHandling="merge"
         [title]="plugin.name || plugin.tag">{{ plugin.config?.settings || plugin.tag }}</a>
    }
    <a routerLink="./user" routerLinkActive="current-tab" i18n-title title="User permissions" i18n>permissions</a>
    <a routerLink="./plugin" routerLinkActive="current-tab" i18n-title title="Ref Plugins" i18n>plugin</a>
    <a routerLink="./template" routerLinkActive="current-tab" i18n-title title="Ext Templates" i18n>template</a>
    @if (store.account.mod) {
      <a routerLink="./backup" routerLinkActive="current-tab" i18n-title title="Backup" i18n>backup</a>
    }
    @if (config.scim && store.account.signedIn) {
      <a routerLink="./password" routerLinkActive="current-tab" i18n-title title="Change Password" i18n>password</a>
    }
  </app-tabs>
  @if (store.view.type) {
    <app-sidebar [tag]="store.view.childTag"></app-sidebar>
  }
  <router-outlet></router-outlet>
</ng-container>
