<div class="container"
     [class.debug]="debug"
     (dragover)="dragOver($event)"
     (drop)="drop($event)">
  <app-login-popup></app-login-popup>
  <app-subscription-bar></app-subscription-bar>
  <router-outlet></router-outlet>
  @if (store.account.banned) {
    <div class="ban-message">
      You have been banned.
    </div>
  }
  <div class="spacer"></div>
  <footer>
    <hr>
    <p class="version-{{ config.version }}" i18n>
      Powered by Jasper <a [href]="website">{{ config.version || '' }}</a>
    </p>
  </footer>
</div>
