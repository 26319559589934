<ng-container *mobxAutorun>
  <form class="form" [formGroup]="dmForm" (ngSubmit)="submit()"
        [appLimitWidth]="fill?.nativeElement" [limitSibling]="true">
    @if (!store.submit.dmPlugin) {
      <label for="to">To:</label>
      <input id="to"
             placeholder="Myself"
             [formControl]="to"
             type="email"
             autocorrect="off"
             autocapitalize="none"
             (blur)="validate($any($event.target))">
      @if (to.touched && !to.value) {
        <div><!-- Warning --></div>
        <div>
            <div class="warning" i18n> Message to myself</div>
        </div>
      }
    }

    <label for="title" i18n>Title:</label>
    <input id="title" type="text" [formControl]="title">

    <app-select-plugin #pluginSelect
                       class="shrink"
                       [text]="true"
                       i18n-title title="Add Plugin"
                       (pluginChange)="bookmarks.toggleTag($event); pluginSelect.plugin = ''"></app-select-plugin>
    <app-form-plugins id="plugins"
                      (togglePlugin)="bookmarks.toggleTag($event)"
                      [tags]="$any(dmForm.value).tags"
                      [group]="dmForm"></app-form-plugins>

    @if (editingViewer) {
      <span><!-- Viewer --></span>
      <app-viewer [text]="dmForm.value.comment"
                  [tags]="$any(dmForm.value).tags"
                  (comment)="comment.setValue($event)"></app-viewer>
    } @else {
      <span i18n>Mesage:</span>
      <div #fill>
        <app-editor class="bubble"
                    [control]="comment"
                    [fillWidth]="fill"
                    (syncEditor)="syncEditor()"
                    [tags]="$any(dmForm.value).tags"
                    (syncTags)="editorTags = $event"
                    [autoFocus]="true"></app-editor>
      </div>
    }

    <app-tags #tags
              [group]="dmForm"
              (syncTags)="syncTags($event)"></app-tags>

    @for (e of serverError; track e) {
      <span><!-- Unexpected Error --></span>
      <div class="error">{{ e }}</div>
    }

    <span><!-- Buttons --></span>
    <span class="buttons right">
      @if (submitting) {
        <app-loading [inline]="true"></app-loading>
      }
      <button type="submit" [disabled]="submitting || submitted && !dmForm.valid" i18n>Send</button>
    </span>
  </form>
</ng-container>
