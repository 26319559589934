@for (s of spots; track s.index) {
  <div class="backgammon-spot"
       [style.grid-column]="s.col > 6 ? s.col + 2 : s.col + 1"
       [style.grid-row]="s.top ? 1 : 2"
       [class.move]="s.move"
       [class.red]="s.red"
       [class.top]="s.top"
       [class.drag-source]="dragSource===s.index"
       cdkDropList
       [cdkDropListData]="s.index"
       (cdkDropListDropped)="drop($any($event))"
       (click)="onClick(s.index)"
       (contextmenu)="moveHighest($event, s.index)">
    @for (p of s.pieces; track p; let pi = $index) {
      <div class="backgammon-piece"
           [class.stacked]="pi > 4"
           [class.double-stacked]="pi > 9"
           [class.bounce]="s.bounce && pi >= s.pieces.length - s.bounce"
           cdkDrag
           cdkDragBoundary=".backgammon-board"
           (cdkDragStarted)="onDragStarted(s.index)"
           (cdkDragExited)="dragSource = -1"
           [cdkDragData]="p"
           [cdkDragDisabled]="!writeAccess">{{ drawPiece(p) }}</div>
    }
  </div>
}
<div class="backgammon-off black"
     [style.grid-column]="1"
     [style.grid-row]="1"
     [class.move]="moveBlackOff"
     cdkDropList
     [cdkDropListData]="-2"
     (cdkDropListDropped)="drop($any($event))"
     (click)="onClickOff()"></div>
<div class="backgammon-off red"
     [style.grid-column]="1"
     [style.grid-row]="2"
     [class.move]="moveRedOff"
     cdkDropList
     [cdkDropListData]="-2"
     (cdkDropListDropped)="drop($any($event))"
     (click)="onClickOff()"></div>
<div class="backgammon-bar black"
     [style.grid-column]="8"
     [style.grid-row]="1"
     [class.move]="moves[-2]"
     [class.drag-source]="dragSource===-1"
     cdkDropList
     [cdkDropListData]="-1"
     (click)="onClickBar()"
     (contextmenu)="moveBarHighest($event)">
  @for (p of blackBar; track p; let pi = $index) {
    <div class="backgammon-piece"
         [class.bounce]="pi >= blackBar.length - redBarBounce"
         cdkDrag
         cdkDragBoundary=".backgammon-board"
         (cdkDragStarted)="onDragStarted(-1)"
         [cdkDragData]="p"
         [cdkDragDisabled]="!writeAccess">{{ drawPiece(p) }}</div>
  }
</div>
<div class="backgammon-bar red"
     [style.grid-column]="8"
     [style.grid-row]="2"
     [class.drag-source]="dragSource===-1"
     cdkDropList
     [cdkDropListData]="-1"
     (click)="onClickBar()"
     (contextmenu)="moveBarHighest($event)">
  @for (p of redBar; track p; let pi = $index) {
    <div class="backgammon-piece"
         [class.bounce]="pi >= redBar.length - redBarBounce"
         cdkDrag
         cdkDragBoundary=".backgammon-board"
         (cdkDragStarted)="onDragStarted(-1)"
         [cdkDragData]="p"
         [cdkDragDisabled]="!writeAccess">{{ drawPiece(p) }}</div>
  }
</div>

<div class="red dice bubble"
     [class.winner]="winner === 'r'"
     [class.backgammon]="winner === 'r' && backgammon"
     [class.gammon]="winner === 'r' && gammon"
     [class.clickme]="!winner && (first && turn === 'r' || !moves.length && turn === 'b')"
     [class.play]="moves.length && turn === 'r'"
     [class.rolling]="rolling === 'r'"
     [class.disabled]="!writeAccess || winner || moves.length || redDice[0] && turn !== 'b'"
     (click)="roll('r')"
     i18n-title title="Red">
  @if (winner === 'r') {
    <div i18n>
      Red Wins
      @if (backgammon) {
        <span i18n-title title="Backgammon">🎲️🎲️🎲️</span>
      }
      @if (gammon && !backgammon) {
        <span i18n-title title="Gammon">🎲️🎲️</span>
      }
      @if (!gammon) {
        <span>🎲️</span>
      }
    </div>
  }
  @if (!redDice[0]) {
    <div i18n>🎲️ Roll</div>
  } @else {
    @if (redDice[0] && !redDice[1]) {
      <div i18n>{{ redDice[0] }}</div>
    } @else {
      @if (redDice[1]) {
        <div i18n>{{ redDice[0] }} - {{ redDice[1] }}</div>
      }
    }
  }
</div>
<div class="black dice bubble"
     [class.winner]="winner === 'b'"
     [class.backgammon]="winner === 'b' && backgammon"
     [class.gammon]="winner === 'b' && gammon"
     [class.clickme]="!winner && (first && turn === 'b' || !moves.length && turn === 'r')"
     [class.play]="moves.length && turn === 'b'"
     [class.rolling]="rolling === 'b'"
     [class.disabled]="!writeAccess || winner || moves.length || blackDice[0] && turn !== 'r'"
     (click)="roll('b')"
     i18n-title title="Black">
  @if (winner === 'b') {
    <div i18n>
      Black Wins
      @if (backgammon) {
        <span i18n-title title="Backgammon">🎲️🎲️🎲️</span>
      }
      @if (gammon && !backgammon) {
        <span i18n-title title="Gammon">🎲️🎲️</span>
      }
      @if (!gammon) {
        <span>🎲️</span>
      }
    </div>
  }
  @if (!blackDice[0]) {
    <div i18n>🎲️ Roll</div>
  } @else {
    @if (blackDice[0] && !blackDice[1]) {
      <div i18n>{{ blackDice[0] }}</div>
    } @else {
      @if (blackDice[1]) {
        <div i18n>{{ blackDice[0] }} - {{ blackDice[1] }}</div>
      }
    }
  }
</div>
