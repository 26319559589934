<ng-container *mobxAutorun>
  @if (store.view.ref) {
    @if (admin.getPlugin('plugin/comment')) {
      <app-comment-reply [to]="store.view.ref"
                         quote=""
                         [tags]="replyTags"
                         [selectResponseType]="true"
                         [newResp$]="newResp$"
                         [newComment$]="newComment$"
                         [newThread$]="newThread$"></app-comment-reply>
    }
    @if (comments) {
      <div class="summary-box">
        <h5 routerLink="./comments" i18n>Comments:</h5>
        <app-thread-summary query="plugin/comment:!plugin/delete"
                            [commentView]="true"
                            [source]="store.view.ref"
                            [newRefs$]="newComment$"
                            [depth]="1"
                            [pageSize]="summaryItems"></app-thread-summary>
        @if (moreComments) {
          <a routerLink="./comments" queryParamsHandling="merge" class="load-more" i18n>
            more comments
          </a>
        }
      </div>
    }
    @if (threads) {
      <div class="summary-box thread">
        <h5 routerLink="./thread" i18n>Threads:</h5>
        <app-thread-summary query="plugin/thread:!plugin/comment:!plugin/delete"
                            [source]="store.view.ref"
                            [newRefs$]="newThread$"
                            [depth]="1"
                            [pageSize]="summaryItems"></app-thread-summary>
        @if (threads > summaryItems) {
          <a routerLink="./thread"
             queryParamsHandling="merge"
             class="load-more no-select" i18n>
            load more
          </a>
        }
      </div>
    }
    @if (responses) {
      <div class="summary-box">
        <h5 routerLink="./responses" i18n>Responses:</h5>
        <app-ref-list [page]="query.page"
                      [pageControls]="false"></app-ref-list>
        @if (responses > summaryItems) {
          <a routerLink="./responses"
             queryParamsHandling="merge"
             class="load-more no-select" i18n>
            more responses
          </a>
        }
      </div>
    }
  } @else {
    <app-loading></app-loading>
  }
</ng-container>
