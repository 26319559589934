import * as moment from 'moment';
import { Plugin } from '../model/plugin';

export const commentPlugin: Plugin = {
  tag: 'plugin/comment',
  name: $localize`💬️ Comment`,
  config: {
    mod: $localize`💬️ Comment`,
    type: 'plugin',
    default: true,
    genId: true,
    internal: true,
    responseButton: $localize`💬️`,
    generated: $localize`Generated by jasper-ui ${moment().toISOString()}`,
    description: $localize`Activates built-in comment support and allows users to create comments.`,
    aiInstructions: `# plugin/comment
    The plugin/comment tag indicates the Ref represents a comment on it's source.`,
    icons: [{ thumbnail: $localize`💬️`, order: 1 }],
    filters: [
      { query: 'plugin/comment', label: $localize`💬️ comments`, group: $localize`Plugins 🧰️` },
    ],
    reply: ['internal', 'plugin/comment'],
  },
  generateMetadata: true,
};
