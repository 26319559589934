import * as moment from 'moment';
import { Template } from '../model/template';

export const experimentsConfig: Template = {
  tag: 'experiments',
  name: $localize`🧪️ Experiments`,
  config: {
    mod: $localize`🧪️ Experiments`,
    type: 'config',
    generated: $localize`Generated by jasper-ui ${moment().toISOString()}`,
    description: $localize`Show experimental plugins on setup page`,
  }
};
