import * as moment from 'moment';
import { Plugin } from '../model/plugin';

export const personPlugin: Plugin = {
  tag: 'plugin/person',
  name: $localize`📇️‍ Person`,
  config: {
    type: 'semantic',
    add: true,
    generated: $localize`Generated by jasper-ui ${moment().toISOString()}`,
    description: $localize`Adds filtering and stylizing to support people semantics.`,
    published: $localize`born`,
    icons: [{ label: $localize`📇️`, order: 2 }],
    submit: $localize`📇️ person`,
    filters: [
      { query: 'plugin/person', label: $localize`📇️‍ people`, group: $localize`Plugins 🧰️` },
    ],
    // language=CSS
    css: `
      .plugin_person .thumbnail {
        border-radius: 24px !important;
        height: 48px !important;
        background-size: cover !important;
      }
    `,
  },
};
