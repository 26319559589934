<ng-container *mobxAutorun>
  @if (!store.account.signedIn) {
    @if (config.login) {
      <a [href]="config.loginLink" i18n>login</a>
      <span *ngIf="config.signup" i18n> or </span>
    }
    @if (config.signup) {
      <a [href]="config.signup" i18n>sign up</a>
    }
    @if (config.login || config.signup) {
      <span> | </span>
    }
    <a routerLink="/settings" i18n>settings</a>
  } @else {
    @if (store.account.localTag) {
      <a class="author"
         [title]="fullUserTagAndRole"
         [routerLink]="['/tag', store.account.tag]">{{ shortUserTag }}</a>
      @if (admin.getTemplate('dm') || admin.inbox.length || (admin.getTemplate('user') && store.account.userTag)) {
        @if (admin.getTemplate('user') && store.account.userTag && store.account.notifications) {
          | {{ store.account.notifications }} <a class="inbox notification" routerLink="/inbox/unread">✉️</a>
        } @else {
          | <a class="inbox" routerLink="/inbox">✉️</a>
        }
      }
      |
    }
    <a routerLink="/settings" i18n>settings</a>
    @if (store.view.updates) {
      <a routerLink="/settings/setup"
         i18n-title title="Updates available in setup" i18n>🌟️</a>
    }
    @if (config.logout) {
      | <a [href]="config.logout" i18n>logout</a>
    }
    @if (config.support) {
      | <a routerLink="/submit/dm" [queryParams]="{ to: config.support }" i18n>help</a>
    }
  }
</ng-container>
