<div class="text-wrapper">
  <div class="toolbar">
    <button type="button"
            (click)="toggleFullscreen()"
            i18n-title title="Fullscreen editor"
            i18n>⛶</button>
    <button type="button"
            (click)="toggleHelp()"
            i18n-title title="Help"
            i18n>❓️</button>
    @if (fullscreen) {
      <button type="button"
              (click)="toggleStacked()"
              i18n-title title="Stacked or side by side layout toggle"
              i18n>/</button>
    }
    @if (fullscreen || currentText) {
      <button type="button"
              (click)="togglePreview()"
              i18n-title title="Show preview"
              i18n>📝️</button>
    }
    @for (button of editorPushButtons; track button; let i = $index) {
      @if (!button.event) {
        <button type="button"
                (click)="toggleTag(button)"
                [title]="button.title" i18n>{{(tags?.includes(button.toggle!) ? button.labelOn : button.labelOff) || button.label }}</button>
      } @else {
        <button type="button"
                (click)="fireEvent(button.event)"
                [title]="button.title" i18n>{{ button.label }}</button>
      }
    }
    @for (button of editorRibbons; track button; let i = $index) {
      <div class="editor-toggle">
        <input type="checkbox"
               [style.display]="'none'"
               [id]="'ribbon-' + id + i"
               [checked]="tags?.includes(button.toggle!)"
               (click)="toggleTag(button)">
        <label [for]="'ribbon-' + id + i"
               [class.on]="tags?.includes(button.toggle!)"
               [title]="button.title">{{(tags?.includes(button.toggle!) ? button.labelOn : button.labelOff) || button.label }}</label>
      </div>
    }
    @if (selectResponseType && responseButtons.length > 1) {
      <div class="toolbar-toggle toolbar-toggle-{{toggleResponse}}">
        @for (plugin of responseButtons; track plugin) {
          <button type="button"
                  class="response-button"
                  (click)="setResponse(plugin.tag)"
                  [class.selected]="tags?.includes(plugin.tag)"
                  [title]="plugin.name" i18n>{{ plugin.config?.responseButton }}</button>
        }
      </div>
    }
  </div>
  <textarea #editor
            [formControl]="control"
            (touchstart)="fullscreenDefault = fullscreenDefault === undefined ? true : undefined"
            (focusin)="editing = true"
            (pointerup)="onSelect()"
            (select)="onSelect()"
            (selectionchange)="onSelect()"
            (input)="setText($any($event).target.value)"
            (blur)="syncText($any($event).target.value)"
            [appFillWidth]="fillWidth"
            [padding]="padding"
            [appAutofocus]="autoFocus"></textarea>
  <div class="floating-toggles">
  </div>
</div>
@if (preview && (fullscreen || currentText)) {
  <app-md class="expand"
          [origin]="store.account.origin"
          [text]="currentText"
          [plugins]="tags"
          [appLimitWidth]="editor"></app-md>
}

<ng-template #help>
  <div class="editor-help-popup" (click)="toggleHelp()">
    <a target="_blank" href="https://jfcere.github.io/ngx-markdown/cheat-sheet">Markdown</a>
  </div>
  <div class="editor-help-popup" (click)="toggleHelp()">
    <a target="_blank" href="https://katex.org/docs/supported.html">LaTeX plugin</a>
  </div>
</ng-template>
