<form class="form" [formGroup]="invoiceForm" (ngSubmit)="submit()"
      [appLimitWidth]="fill" [limitSibling]="true">

  <label for="to" i18n>Queue:</label>
  <input id="to"
         type="email"
         autocorrect="off"
         autocapitalize="none"
         [value]="queue || ''"
         (change)="queue = $any($event.target).value">

  <label for="qr" i18n>QR Code:</label>
  <div class="form-array">
    <input id="qr"
           type="text"
           [formControl]="url"
           (blur)="validate($any($event.target))">
    <app-qr-scanner (data)="$event && url.setValue($event)"></app-qr-scanner>
  </div>

  <label for="title" i18n>Title:</label>
  <input id="title"
         type="text"
         [formControl]="title"
         (blur)="validate($any($event.target))">
  <span><!-- Title Errors --></span>
  <div>
    @if (title.touched && title.errors?.['required']) {
      <div class="error" i18n>
        Title must not be blank.
      </div>
    }
  </div>

  <span><!-- Message --></span>
  <div #fill>
    <app-editor class="bubble"
                [control]="comment"
                [fillWidth]="fill"
                (syncEditor)="syncEditor()"
                [tags]="$any(invoiceForm.value).tags"
                (syncTags)="editorTags = $event"></app-editor>
  </div>

  @for (e of serverError; track e) {
    <span><!-- Unexpected Error --></span>
    <div class="error">{{ e }}</div>
  }

  <span><!-- Buttons --></span>
  <span class="buttons right">
      @if (submitting) {
        <app-loading [inline]="true"></app-loading>
      }
    <button type="submit" [disabled]="submitting || submitted && !invoiceForm.valid" i18n>Send</button>
  </span>
</form>
