import * as moment from 'moment';
import { Plugin } from '../model/plugin';

export const pipPlugin: Plugin = {
  tag: 'plugin/pip',
  name: '🪟 Picture in Picture',
  config: {
    type: 'plugin',
    experimental: true,
    add: true,
    generated: $localize`Generated by jasper-ui ${moment().toISOString()}`,
    icons: [{ label: $localize`🪟` }],
    filters: [
      { query: 'plugin/pip', label: $localize`🪟 pip`, group: $localize`Plugins 🧰️` },
    ],
    description: $localize`Open embed picture-in-picture..`,
  }
};
