import * as moment from 'moment';
import { Plugin } from '../model/plugin';

export const qrPlugin: Plugin = {
  tag: 'plugin/qr',
  name: $localize`🔲 QR Code`,
  config: {
    type: 'plugin',
    add: true,
    generated: $localize`Generated by jasper-ui ${moment().toISOString()}`,
    description: $localize`Activates built-in qr code support and allows users to create qr codes.`,
    icons: [{ label: $localize`🔲` }],
    filters: [
      { query: 'plugin/qr', label: $localize`🔲 qr`, group: $localize`Plugins 🧰️` },
    ],
    advancedForm: [{
      key: 'url',
      type: 'qr',
      props: {
        label: $localize`URL:`,
      },
    }],
  },
  defaults: {},
  schema: {
    optionalProperties: {
      url: { type: 'string' },
    },
  },
};
