import { $localize } from '@angular/localize/init';
import * as moment from 'moment';
import { Plugin } from '../model/plugin';
import { Mod } from '../model/tag';
import { chessAiPlugin, chessPlugin, chessTemplate } from './chess';

export const deltaPlugin: Plugin = {
  tag: 'plugin/delta',
  name: $localize`⏳️ Delta`,
  config: {
    default: true,
    mod: $localize`⏳️ Delta`,
    generated: $localize`Generated by jasper-ui ${moment().toISOString()}`,
    description: $localize`Transform Refs by running scripts.`,
    icons: [
      { label: $localize`⏳️`, noResponse: '+plugin/delta', order: -10 },
      { tag: '_plugin/delta', label: $localize`⏳️`, noResponse: '+plugin/delta', order: -10 },
    ],
    filters: [{ query: 'plugin/delta|_plugin/delta', label: $localize`⏳️ Working`, group: $localize`Plugins 🧰️` }],
  },
};

export const deltaSignaturePlugin: Plugin = {
  tag: '+plugin/delta',
  name: $localize`⏳️ Delta Signature`,
  config: {
    default: true,
    mod: $localize`⏳️ Delta`,
    generated: $localize`Generated by jasper-ui ${moment().toISOString()}`,
    description: $localize`Transform Refs by running scripts.`,
  },
  generateMetadata: true,
};

export const deltaMod: Mod = {
  plugins: {
    deltaPlugin,
    deltaSignaturePlugin,
  },
};
