import * as moment from 'moment';
import { Plugin } from '../model/plugin';

export const emailPlugin: Plugin = {
  tag: 'plugin/email',
  name: $localize`📧️ Email`,
  config: {
    type: 'plugin',
    default: true,
    add: true,
    generated: $localize`Generated by jasper-ui ${moment().toISOString()}`,
    description: $localize`Render e-mail specific formatting.`,
    icons: [
      { label: $localize`📧️` },
    ],
    filters: [
      { query: 'plugin/email', label: $localize`📧️ email`, group: $localize`Plugins 🧰️` },
    ],
  },
};
