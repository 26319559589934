<div class="row" [formGroup]="adminForm">
  <form class="form" formGroupName="mods" (ngSubmit)="install()">
    <span><!-- Title --></span>
    <h2 i18n>Install Mods</h2>
    <span><!-- Description --></span>
    <p i18n>
      Quickly turn on and off mods bundled with this client.
      Visit the <a routerLink="../plugin">plugins</a> or
      <a routerLink="../template">templates</a> settings page to manage all
      plugins and templates.
    </p>

    <span><!-- Buttons --></span>
    <span class="buttons right">
      <button type="button" (click)="updateAll()" *ngIf="store.view.updates" i18n>🌟️ Update All</button>
      <button type="button" (click)="selectAll()" *ngIf="selectAllToggle" i18n>Select None</button>
      <button type="button" (click)="selectAll()" *ngIf="!selectAllToggle" i18n>Select All</button>
      <button type="submit" [disabled]="submitted && !adminForm.valid" i18n>Save</button>
    </span>

    <ng-container *ngIf="serverError.length || installMessages.length">
      <span class="mobile-block"><!-- Log --></span>
      <div class="log mobile-block">
        <div *ngFor="let e of serverError" class="error">{{ e }}</div>
        <div *ngFor="let i of installMessages">{{ i }}</div>
      </div>
    </ng-container>

    <ng-container *ngFor="let group of modGroups | keyvalue">
      <label [style.text-transform]="'capitalize'" i18n>{{ group.key }}</label>
      <div class="bubble">
        <ng-container *ngFor="let e of group.value">
          <div *ngIf="experiments || !e[1].config?.experimental" [title]="e[1].config?.description || ''" class="nowrap" [class.deleted]="disabled(e[1])">
            <input id="mod-{{ modLabel(e[1].config?.mod || e[0]) }}" type="checkbox" [formControlName]="e[0]">
            <label for="mod-{{ modLabel(e[1].config?.mod || e[0]) }}" [title]="e[1].config?.description || ''">{{ e[1].config?.mod || e[1].name || e[1].tag }}</label>
            <span *ngIf="e[1].config?.experimental">🧪️</span>
            <span *ngIf="needsModUpdate(e[1])" class="update">
                <span class="fake-link no-select" (click)="updateMod(e[1])" i18n>🌟️ update</span>
              </span>
          </div>
        </ng-container>
      </div>
    </ng-container>

  </form>
  <span *ngIf="serverError.length || installMessages.length" class="log mobile-hide">
    <div *ngFor="let e of serverError" class="error">{{ e }}</div>
    <div *ngFor="let i of installMessages">{{ i }}</div>
  </span>
</div>
