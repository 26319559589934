<div class="controls">
  @if (page) {
    <label i18n-title title="pages: {{ page.totalPages }}, results: {{ page.totalElements }}" i18n>more results: </label>
    @if (hasQuery) {
      <a class="first-page"
         routerLink="./"
         [class.disabled]="page.first"
         [queryParams]="{ pageNumber: store.view.defaultPageNumber ? 0 : null }"
         queryParamsHandling="merge" i18n>first</a>
      <span> | </span>
      @if (showPrev) {
        <a class="prev-page"
           routerLink="./"
           routerLinkActive="disabled"
           [queryParams]="{ pageNumber: prev }"
           queryParamsHandling="merge" i18n>prev</a>
        <span> | </span>
      }
    }
    <a class="next-page"
       routerLink="./"
       [class.disabled]="page.last"
       [queryParams]="{ pageNumber: next }"
       (click)="scrollUp()"
       queryParamsHandling="merge" i18n>next</a>
    <span> | </span>
    <a class="last-page"
       *ngIf="showPageLast"
       routerLink="./"
       [class.disabled]="page.last"
       [queryParams]="{ pageNumber: last }"
       queryParamsHandling="merge" i18n>last</a>
  }

  <label for="pageSize" i18n> size: </label>
  <select id="pageSize" [(ngModel)]="pageSize">
    <option *ngIf="outOfPageSizeRange(store.view.pageSize)" [value]="store.view.pageSize">{{ store.view.pageSize }}</option>
    <option *ngFor="let size of pageSizes" [value]="size">{{ size }}</option>
  </select>

  <ng-container *ngIf="!hideCols && (cols || colsChanged)">
    <label for="cols" i18n> cols: </label>
    <select id="cols" [(ngModel)]="cols">
      <option [value]="0" i18n>default</option>
      <option *ngIf="outOfColSizeRange(store.view.cols || 1)" [value]="store.view.cols">{{ store.view.cols }}</option>
      <option *ngFor="let size of colSizes" [value]="size">{{ size }}</option>
    </select>
  </ng-container>
</div>
<div *ngIf="page" class="print-view" i18n>
  Page {{ page.number + 1 }} of {{ page.totalPages }} (size: {{ page.size }})
</div>
