<ng-container *mobxAutorun>
  <app-tabs>
    <a class="logo" routerLink="/"></a>
    <h5 class="fake-link" routerLink="." [queryParams]="{}">{{ store.view.name }}</h5>
    @if (store.view.extTemplate) {
      <a routerLink="./"
         [class.current-tab]="store.view.noView"
         [queryParams]="{view: null}"
         queryParamsHandling="merge" i18n>{{ store.view.extTemplate.config?.view || store.view.extTemplate.tag }}</a>
      <a routerLink="./"
         [class.current-tab]="store.view.list"
         [queryParams]="{view: 'list'}"
         queryParamsHandling="merge" i18n>list</a>
    } @else if (store.view.activeExts.length) {
      @for (x of store.view.activeExts; track x; let i = $index) {
        <a routerLink="./"
           [class.current-tab]="store.view.viewTag === x.tag"
           [queryParams]="{view: i === 0 ? null : x.tag}"
           queryParamsHandling="merge" i18n>{{ x.name || x.tag }}</a>
      }
      <a routerLink="./"
         [class.current-tab]="store.view.list"
         [queryParams]="{view: 'list'}"
         queryParamsHandling="merge" i18n>list</a>
    } @else {
      <a routerLink="./"
         [class.current-tab]="store.view.noView"
         [queryParams]="{view: null}"
         queryParamsHandling="merge" i18n>list</a>
    }
    @for (x of store.view.globalExts; track x.tag) {
      <a routerLink="./"
         [class.current-tab]="store.view.view === x.tag"
         [queryParams]="{view: x.tag}"
         queryParamsHandling="merge" i18n>{{ x.config?.view || x.name || x.tag }}</a>
    }
  </app-tabs>
  @if (!loading) {
    <app-sidebar [floating]="store.view.floatingSidebar"
                 [ext]="store.view.viewExt"
                 [tag]="store.view.tag"
                 [activeExts]="store.view.activeExts"></app-sidebar>
  }
  @if (query.error?.status !== 403) {
    @if (!loading) {
      <app-lens [page]="query.page"
                [showAlarm]="!store.view.alarm"
                [showVotes]="store.view.isVoteSorted"
                [tag]="store.view.tag"
                [ext]="store.view.viewExt"
                [fullPage]="true"
                [size]="store.view.pageSize"
                [cols]="store.view.cols"
                [sort]="store.view.sort"
                [filter]="store.view.filter"
                [search]="store.view.search"></app-lens>
    } @else {
      <app-loading></app-loading>
    }
  } @else {
    <div class="error-403" i18n>Access Denied</div>
  }
</ng-container>
