<ng-container *mobxAutorun>
  <app-tabs>
    <a class="logo" routerLink="/"></a>
    <h5 class="fake-link" routerLink="." i18n>
      Submit
      @if (selectedPlugin) {
        <span>{{ selectedPlugin.name || selectedPlugin.tag }}</span>
      }
    </h5>
    <a routerLink="/submit"
       [class.current-tab]="store.submit.web && !store.submit.genId"
       [queryParams]="{ url: url.value, tag: store.submit.withoutGenId }"
       [replaceUrl]="true"
       i18n-title title="📝️ Link Post"
       queryParamsHandling="merge" i18n>link</a>
    <a routerLink="/submit/text"
       [class.current-tab]="store.submit.text"
       [queryParams]="{ url: null, tag: store.submit.withoutGenId }"
       i18n-title title="📝️ Text Post"
       queryParamsHandling="merge" i18n>text</a>
    <a class="fake-link"
       routerLink="/submit/upload"
       [class.current-tab]="store.submit.upload"
       i18n-title title="💾️ Upload"
       queryParamsHandling="merge" i18n>upload</a>
    @if (!admin.isWikiExternal()) {
      <a routerLink="/submit"
         [class.current-tab]="store.submit.wiki"
         [queryParams]="{ url: wikify, tag: store.submit.withoutGenId }"
         [replaceUrl]="true"
         i18n-title title="📔️ Wiki Page"
         queryParamsHandling="merge" i18n>wiki</a>
    }
    @if (admin.getTemplate('dm')) {
      <a routerLink="/submit/dm"
         [class.current-tab]="store.submit.subpage === 'dm' && !store.submit.dmPlugin"
         [queryParams]="{ to: null, tag: null }"
         i18n-title title="📨️ Direct Message"
         queryParamsHandling="merge" i18n>dm</a>
    }
    @for (p of store.submit.submitGenId; track p) {
      @if (p.config?.submitDm) {
        <a routerLink="/submit/dm"
           [class.current-tab]="store.submit.subpage === 'dm' && store.submit.to[0] === p.tag"
           [queryParams]="{ to: p.tag, tag: null }"
           [replaceUrl]="true"
           [title]="p.name"
           queryParamsHandling="merge">{{ p.config!.submit }}</a>
      } @else if (p.config?.submitText) {
        <a routerLink="/submit/text"
           [class.current-tab]="store.submit.tags.includes(p.tag) && store.submit.url"
           [queryParams]="{ tag: submitInternal(p.tag), url: genUrl }"
           [replaceUrl]="true"
           [title]="p.name"
           queryParamsHandling="merge">{{ p.config!.submit }}</a>
      } @else {
        <a routerLink="/submit/web"
           [class.current-tab]="store.submit.tags.includes(p.tag) && store.submit.url"
           [queryParams]="{ tag: submitInternal(p.tag), url: genUrl }"
           [replaceUrl]="true"
           [title]="p.name"
           queryParamsHandling="merge">{{ p.config!.submit }}</a>
      }
    }
  </app-tabs>
  <router-outlet></router-outlet>

  @if (!store.submit.subpage) {
    <div class="full-page-form">
      <form class="form" [formGroup]="submitForm" (ngSubmit)="submit()">
        @if (!store.submit.wiki && selectedPlugin) {
          <span i18n>Type:</span>
          <div>
            <span>{{ selectedPlugin.name || selectedPlugin.tag }}</span>
            @if (selectedPlugin.config?.description) {
              <p class="description">
                {{ selectedPlugin.config!.description }}
              </p>
            }
          </div>
        }
        @if (store.submit.wiki) {
          <span i18n>Type:</span>
          <div>
            <span i18n>Wiki</span>
            <p class="description" i18n>
              Create a text post that can be linked to via double bracket [[Wiki]] style.
            </p>
          </div>
        }

        @if (store.submit.wiki) {
          <label for="url" class="big">Title:</label>
        } @else {
          <label for="url" class="big"></label>
        }
        <div class="form-array">
          @if (store.submit.web) {
            <app-select-plugin [(plugin)]="plugin"
                               [settings]="!!admin.getPluginSettings(store.submit.tags).length"></app-select-plugin>
          }
          <input id="url"
                 [placeholder]="placeholder"
                 class="big"
                 type="text"
                 size="1"
                 autocomplete="off"
                 formControlName="url"
                 appAutofocus>
          @if (admin.getPlugin('plugin/qr') && !store.submit.wiki) {
            <app-qr-scanner (data)="scanQr($event)"></app-qr-scanner>
          }
        </div>

        @for (e of serverErrors; track e) {
          <span><!-- Unexpected Error --></span>
          <div class="error">{{ e }}</div>
        }

        <span><!-- Tags --></span>
        <div class="form-array right">
          @for (tag of store.submit.tags | plugins; track tag) {
            <span>{{ tag }}</span>
          }
        </div>

        <br>
        <div class="validations">
          @for (v of validations; track v) {
            @if (v.passed) {
              <div>✅️ &nbsp;{{ v.name }}</div>
            } @else {
              <div>❌️ &nbsp;{{ v.name }}</div>
            }
          }
        </div>

        <span><!-- Buttons --></span>
        <span class="buttons right">
          <button type="submit" [disabled]="!submitForm.valid && !existingRef"
                  i18n-title title="Submit link"
                  i18n>{{ repost ? 'Repost' : 'Next' }}</button>
        </span>
      </form>

      @if (existingRef) {
        <br>
        <app-ref [ref]="existingRef" [showToggle]="true"></app-ref>
      }
    </div>
  }
</ng-container>
