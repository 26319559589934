<div class="list-container">
  <ng-container *ngFor="let user of page?.content; let i = index">
    <div class="list-number">{{ i + page!.number * page!.size + 1 }}</div>
    <app-user [user]="user" [profile]="getProfile(user)"></app-user>
  </ng-container>
  <ng-container *ngFor="let profile of scim?.content; let i = index">
    <ng-container *ngIf="!hasUser(profile.tag)">
      <div class="list-number">{{ i + scim!.number * scim!.size + (page ? page.content.length + page.number * page.size : 0) }}</div>
      <app-user [profile]="profile"></app-user>
    </ng-container>
  </ng-container>
</div>
<app-loading *ngIf="!page"></app-loading>
<app-page-controls *ngIf="page && !page.empty"
                   [page]="page"></app-page-controls>

<p class="no-results" *ngIf="page && page.empty" i18n>
  No results found
</p>
