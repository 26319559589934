@if (!confirming && !acting && !minTimeout) {
  <span class="fake-link" (click)="confirming = true">
    <ng-content></ng-content>
  </span>
} @else if (confirming) {
  <span *ngIf="confirming" > {{ message }}&nbsp;
    <a class="fake-link" (click)="confirm()">yes</a>&nbsp;/&nbsp;
    <a class="fake-link" (click)="confirming = false">no</a>&nbsp;
  </span>
} @else {
  <app-loading [inline]="true"></app-loading>
}
