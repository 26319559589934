<div class="list-container" *ngIf="page">
  <ng-container *ngFor="let tag of page?.content; let i = index">
    <div class="list-number">{{ i + page!.number * page!.size + 1 }}</div>
    <app-plugin [plugin]="$any(tag)"></app-plugin>
  </ng-container>
</div>
<app-loading *ngIf="!page"></app-loading>
<app-page-controls *ngIf="page && !page.empty"
                   [page]="page"></app-page-controls>

<p class="no-results" *ngIf="page && page.empty" i18n>
  No results found
</p>
