<details class="advanced">
  <summary><span i18n>Bulk tools</span> <app-loading *ngIf="batchRunning" [batch]="true"></app-loading></summary>
  @if (empty) {
    <span i18n>no results</span>
  } @else {
    <span class="fake-link" (click)="download()" i18n>download</span>
    @if (type === 'ref') {
      <a class="fake-link" (click)="toggle()" i18n>toggle</a>
      <a *ngIf="store.account.user" class="fake-link" routerLink="/submit" [queryParams]="{source: urls}" i18n>reply</a>
      <app-inline-tag [action]="tag$" i18n>tag</app-inline-tag>
      <app-inline-url [action]="thumbnail$" [value]="defaultThumbnail" i18n>thumbnail</app-inline-url>
      <app-confirm-action [action]="copy$" i18n>copy</app-confirm-action>
    }
    <app-confirm-action [action]="delete$" i18n>delete</app-confirm-action>
    @if (type === 'ref') {
      @if (admin.getPlugin('plugin/invoice')) {
        <a routerLink="/submit/invoice" [queryParams]="{url: urls}" i18n>invoice</a>
      }
      @for (a of groupedActions | keyvalue; track a.key) {
        @if (a.value[0].confirm) {
          <app-confirm-action [message]="a.value[0].confirm!"
                              [action]="doAction$(a.value)"
                              [appTitle]="a.value">{{ a.key }}</app-confirm-action>
        } @else {
          <app-inline-button [action]="doAction$(a.value)"
                             [appTitle]="a.value">{{ a.key }}</app-inline-button>
        }
      }
    }
  }

  @for (e of serverError; track e) {
    <div class="error">{{ e }}</div>
  }
</details>
