import * as moment from 'moment';
import { Plugin } from '../model/plugin';

export const tablePlugin: Plugin = {
  tag: 'plugin/table',
  name: '📏️ Table',
  config: {
    type: 'plugin',
    experimental: true,
    submitText: true,
    editingViewer: true,
    generated: $localize`Generated by jasper-ui ${moment().toISOString()}`,
    description: $localize`Activates built-in table support and allows users to create tables.`,
    aiInstructions: `# plugin/table
    If the plugin/table tag is in the tag list, the Ref comment should be CSV instead of Markdown.`,
    icons: [{ label: $localize`📏️`, order: 2 }],
    filters: [
      { query: 'plugin/table', label: $localize`📏️ table`, group: $localize`Plugins 🧰️` },
    ],
  },
};
