import { $localize } from '@angular/localize/init';
import { filter } from 'lodash-es';
import * as moment from 'moment';
import { Plugin } from '../model/plugin';
import { Ref } from '../model/ref';
import { Tag } from '../model/tag';
import { hasTag, publicTag } from '../util/tag';

export const deletePlugin: Plugin = {
  tag: 'plugin/delete',
  name: $localize`🗑️ Delete Notice`,
  config: {
    internal: true,
    generated: $localize`Generated by jasper-ui ${moment().toISOString()}`,
    description: $localize`Activates built-in delete notice support which clears refs and marks them as deleted instead of actually deleting them.`,
    aiInstructions: ` # plugin/delete
    If you are included in a conversation with more than 2 people, consider whether you should respond or not.
    If it seems inappropriate to respond, simply reply with a blank message and the plugin/delete tag. This will
    mark the AI response as completed, but will not interrupt the user.
    `,
    icons: [{ thumbnail: $localize`🗑️`, order: -1 }],
    filters: [
      { query: 'plugin/delete', label: $localize`🗑️ deleted`, group: $localize`Filters 🕵️️` },
    ],
  },
};

export function deleteNotice(ref: Ref): Ref {
  if (hasTag('locked', ref)) {
    ref.tags!.push('plugin/delete', 'internal');
    return ref;
  }
  const tags = ['plugin/delete', 'internal'];
  tags.push(...filter(ref.tags, t => {
    if (ref.plugins?.[t]) return false;
    if (t.startsWith('+plugin/')) return false;
    if (!publicTag(t)) return true;
    if (t === 'locked') return true;
    if (t === 'public') return true;
    return false;
  }));
  if (hasTag('locked', ref)) {
    ref.tags = tags;
    return ref;
  }
  return {
    url: ref.url,
    origin: ref.origin,
    tags,
    created: ref.created,
    published: ref.published,
    modifiedString: ref.modifiedString,
  };
}

export function tagDeleteNotice(tag: Tag) {
  return {
    tag: tag.tag + '/deleted',
    origin: tag.origin,
    config: {},
  };
}
