<div class="row">
  <div class="stack">
    <h2 class="link blog-title" [class.remote]="!local">
      @if (clickableLink) {
        <app-nav [url]="url" [text]="title" [external]="true"></app-nav>
      } @else {
        <span>{{ title }}</span>
      }
    </h2>
    <div class="link-below">
      <div class="stack">
        <div class="info">
          <ng-container *ngIf="authors.length" i18n>
            by
            <ng-container *ngIf="authorExts$ | async as authorExts; else authorNoExts">
              <ng-container *ngFor="let user of authorExts">
                <a class="user tag"
                   [title]="formatAuthor(user.tag + user.origin)"
                   [routerLink]="['/tag', user.tag + user.origin]"
                   [appTitle]="user">{{ user.name || formatAuthor(user.tag) }}</a>
              </ng-container>
            </ng-container>
            <ng-template #authorNoExts>
              <ng-container *ngFor="let t of authors">
                <a class="user tag"
                   [title]="formatAuthor(t)"
                   [routerLink]="['/tag', t]">{{ formatAuthor(t) }}</a>
              </ng-container>
            </ng-template>
          </ng-container>
          <ng-container *ngIf="tags.length" i18n>
            tagged
            <ng-container *ngIf="tagExts$ | async as tagExts; else tagNoExts">
              <ng-container *ngFor="let e of tagExts">
                <a class="tag" [routerLink]="['/tag', e.tag]" [appTitle]="e">{{ e.name || e.tag }}</a>
              </ng-container>
            </ng-container>
            <ng-template #tagNoExts>
              <ng-container *ngFor="let t of tags">
                <a class="tag" [routerLink]="['/tag', t]">{{ t }}</a>
              </ng-container>
            </ng-template>
          </ng-container>
          <ng-container *ngFor="let icon of icons">
            <span *ngIf="showIcon(icon)"
                  class="icon"
                  [class.filter-toggle]="icon.tag"
                  (click)="clickIcon(icon, store.hotkey)"
                  [appTitle]="icon"
                  [ref]="ref">{{ icon.label }}</span>
          </ng-container>
          <span [title]="ref.published?.toISOString()">{{ ref.published?.fromNow() }}</span>
          <ng-container *ngIf="!authors.length && ref.created && !local">
            on <a class="origin tag" [routerLink]="['/tag', ref.origin]">{{ ref.origin }}</a>
          </ng-container>
        </div>
        <div class="actions">
          <a [routerLink]="['/ref', ref.url]" [queryParams]="{ origin: nonLocalOrigin }" i18n>permalink</a>
          <a *ngIf="store.account.user" routerLink="/submit" [queryParams]="{source: ref.url}" i18n>reply</a>
          <a *ngIf="comments" [routerLink]="['/ref', ref.url, 'comments']" [queryParams]="{ origin: nonLocalOrigin }" i18n>{comments, plural, =1 {1&thinsp;comment} other {{{ comments }}&thinsp;comments}}</a>
          <a *ngIf="responses" [routerLink]="['/ref', ref.url, 'responses']" [queryParams]="{ origin: nonLocalOrigin }" i18n>{responses, plural, =1 {1&thinsp;citation} other {{{ responses }}&thinsp;citations}}</a>
          <a *ngIf="sources === 1" [routerLink]="['/ref', ref.sources![0]]" [queryParams]="{ origin: nonLocalOrigin }" i18n>parent</a>
          <a *ngIf="sources > 1" [routerLink]="['/ref', ref.url, 'sources']" [queryParams]="{ origin: nonLocalOrigin }" i18n>{{ sources }}&thinsp;sources</a>
          <app-confirm-action #action *ngIf="store.account.mod || writeAccess" [action]="delete$" i18n>delete</app-confirm-action>
          <app-inline-tag #action *ngIf="taggingAccess" [action]="tag$" i18n>tag</app-inline-tag>
          <a *ngIf="writeAccess else source"
             class="fake-link"
             (click)="editing = !editing" i18n>edit</a>
          <ng-template #source>
            <a class="fake-link"
               (click)="viewSource = !viewSource" i18n>source</a>
          </ng-template>
          <ng-container *ngIf="canInvoice">
            <a [routerLink]="['/submit/invoice']"
               [queryParams]="{url: ref.url}" i18n>invoice</a>
          </ng-container>
          <app-action-list [ref]="ref"
                           [repostRef]="repostRef"
                           [groupedActions]="groupedActions"></app-action-list>
        </div>
      </div>
    </div>
  </div>
</div>
<app-viewer *ngIf="!editing" class="blog-body" [ref]="ref"></app-viewer>

<form *ngIf="editing else showErrors" class="form" [formGroup]="editForm" (ngSubmit)="save()">

  <app-ref-form [group]="editForm"></app-ref-form>

  @for (e of serverError; track e) {
    <span><!-- Unexpected Error --></span>
    <div class="error">{{ e }}</div>
  }

  <span><!-- Buttons --></span>
  <span class="buttons right">
    @if (submitting) {
      <app-loading [inline]="true"></app-loading>
    }
    <button type="submit" [disabled]="submitting || submitted && !editForm.valid" i18n>save</button>
    <button type="button" [disabled]="submitting" (click)="editing = false" i18n>cancel</button>
  </span>
</form>

<form *ngIf="viewSource" [formGroup]="editForm">
  <app-ref-form [group]="editForm"></app-ref-form>
</form>

<ng-template #showErrors>
  @for (e of serverError; track e) {
    <div class="error">{{ e }}</div>
  }
</ng-template>
<hr>
