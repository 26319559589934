import * as moment from 'moment';
import { Template } from '../model/template';

export const homeTemplate: Template = {
  tag: 'home',
  name: $localize`🏡️ Home Page`,
  config: {
    type: 'config',
    default: true,
    generated: 'Generated by jasper-ui ' + moment().toISOString(),
    description: $localize`Embeds the viewer of the newest post tagged 'home' to the top of the home page.`,
    filters: [
      { query: 'home', label: $localize`🏡️ home`, group: $localize`Templates 🎨️` },
    ],
  },
};
